@import 'variables.scss';
@import 'bootstrap/scss/bootstrap.scss' layer(bootstrap-rebooot);
@import "theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

html,
body {
    margin: 0;
    height: 100%;
    letter-spacing: 0.5px;
    font-size: 14px;
}

.main-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.scrollable {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 0.5rem;
}

.red-text {
    color: #b00020 !important;
}

.error-message {
    color: #b00020;
    font-size: 0.75rem;
    padding-left: .5rem;
    position: absolute;
    width: 100%;
    z-index: 10;
}

// start - elements style

input:disabled {
    background-color: #eeeeee !important;
}

.p-button {
    padding: 0.65rem 1rem;
    font-weight: normal;
    font-size: 15px;
    letter-spacing: 0.5px;

    &.p-button-rounded {
        border-radius: 13px;
    }
}

.p-inputtext,
.p-inputmask {
    padding: 0.50rem 0.75rem;
    background-color: $color-formcontrol-bg;
    border-radius: 10px;
    border-color: unset;
    border: 1px;
    letter-spacing: 0.5px;

    &.ng-invalid.ng-dirty {
        background-color: $color-formcontrol-bg-err;
    }
}

.p-dropdown {
    background-color: $color-formcontrol-bg;
    border-color: unset;
    border: 1px;
    border-radius: 10px;
    letter-spacing: 0.5px;

    .p-inputtext {
        background-color: unset;
        border: unset;
    }

    .p-dropdown-panel {
        .p-dropdown-header {
            padding: 0.5rem 1rem;
        }

        .p-dropdown-items {
            max-height: 150px;
        }

        .p-dropdown-items .p-dropdown-item {
            padding: 0.75rem 1rem;
        }
    }

    .p-dropdown-items-wrapper {
        max-height: 150px;
    }
}

.p-inputnumber .p-inputnumber-input {
    text-align: right;
    width: 100%;
    letter-spacing: 0.5px;
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}

.progressbar {
    height: 0.75rem; // Height of the pending part
    display: grid;
    position: relative;

    // pending part
    .p-progressbar {
        background-color: #cfd3d4;
        border-radius: 10px;
        height: 0.4rem;
        align-self: center;
        position: static;
    }

    // completed part
    .p-progressbar-value {
        background-color: $color-primary; // Completed part color
        height: 100%;
        position: absolute;
        top: -1%;
        border-radius: 10px;
    }
}

p-toast {
    .p-toast {
        opacity: 1 !important;
        width: unset;
        max-width: 80%;
        position: absolute;
        bottom: 88px;
        //bottom: 55px;

        @media (max-width: 576px) {
            width: 100%;
            max-width: 90%;
        }

        .p-toast-message .p-toast-message-content {
            padding: 1rem;

            .p-toast-message-text {
                margin: 0 .5rem 0 1rem;
            }
        }

        .p-toast-message-content {
            align-items: center !important;
        }

        .p-toast-detail {
            margin: 0 !important;
        }

        .p-toast-message-icon {
            display: flex;
            align-items: center !important;
        }
    }
}

.p-dialog {

    @media (max-width: 576px) {
        width: 90% !important;
        background-color: #fff;
    }

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%);
    background-color: #fff;
}

.p-panel {
    margin-top: 1em;
}

// size of spinner
.p-progress-spinner {
    width: 150px;
    height: 150px;
}

.p-progress-spinner .p-progress-spinner-svg {
    stroke: $color-primary !important;

    // width of the animated circle
    circle {
        stroke-width: 4px;
    }
}

.p-progress-spinner .p-progress-spinner-circle {
    stroke: $color-primary !important;
}

.p-disabled {
    color: lightgray;
}

.p-datepicker {
    //min-width: unset;
    left: unset !important;
    right: 0;

    table td {
        padding: unset;
    }

    .p-datepicker-header {
        padding: unset;

        .p-datepicker-title .p-datepicker-year,
        .p-datepicker-title .p-datepicker-month {
            padding: 0 0.5rem;
        }
    }
}

.p-overlaypanel {
    max-width: 600px;
    max-height: 250px;
    overflow: auto;

    p {
        //text-align: justify;
        margin: unset;

        &.title {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
    }
}

.p-tooltip {
    max-width: 50% !important;
}

// end - elements style

// start - elements error background color
p-dropdown.ng-invalid.ng-dirty {
    .p-dropdown {
        background-color: $color-formcontrol-bg-err;
    }
}

p-inputmask.ng-dirty.ng-invalid,
p-inputnumber.ng-dirty.ng-invalid {
    .p-inputtext {
        background-color: $color-formcontrol-bg-err;
    }
}

p-calendar.ng-dirty.ng-invalid {
    .p-calendar {
        .p-inputtext {
            background-color: $color-formcontrol-bg-err;
        }
    }
}

// end - elements error background color

@media (max-width: 576px) {

    .s-table,
    .s-table thead,
    .s-table tbody,
    .s-table th,
    .s-table td,
    .s-table tr {
        display: block; // Make each element block
    }

    .s-table thead tr {
        display: none; // Hide the header row
    }

    .s-table tr {
        margin-bottom: 15px; // Space between rows
        border: 1px solid #ddd; // Optional border for each row
        //padding: 10px; // Add padding
    }

    .s-table tr:has(td.close) {
        border: none !important; // Remove border for rows containing .close
    }

    .s-table td {
        &.text-end {
            flex-direction: row !important;
            justify-content: space-between;
        }

        &.heifer-details {
            background-color: unset !important;
        }
    }

    .s-table td[data-label] {
        position: relative; // Position for pseudo-elements
        //padding: 10px; // Padding for cells
        text-align: left; // Align text to the left
        display: flex; // Use flexbox
        flex-direction: column; // Stack label and value vertically
        border-width: unset !important;
        background-color: unset !important;
    }

    .s-table td[data-label]::before {
        content: attr(data-label); // Use data-label for headers
        font-weight: bold; // Make it bold
        margin-bottom: 5px; // Space between label and value
    }
}

/* For the entire scrollbar */
::-webkit-scrollbar {
    width: 5px; // Width of the vertical scrollbar
    height: 5px; // Height of the horizontal scrollbar
}

/* Track (background) */
::-webkit-scrollbar-track {
    background: #f1f1f1; // Color of the track
    border-radius: 10px; // Round edges
}

/* Handle (the draggable part) */
::-webkit-scrollbar-thumb {
    background: #888; // Color of the handle
    border-radius: 10px; // Round edges
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; // Color of the handle when hovered
}

/* Optional: for the corner */
::-webkit-scrollbar-corner {
    background: #f1f1f1; // Color for the corner where horizontal and vertical scrollbars meet
}